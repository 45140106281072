.current-reservation, .preferences {
    .column .inner-card {
        min-height: 180px;
    }

    .text-label {
        font-size: 14px;
        font-weight: 400;
        color: #808080;
    }

    .text-title {
        font-size: 18px;
        font-weight: 500;
    }

    .line-height {
        line-height: 16px;
    }

    .status {
        width: fit-content;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 6px;
        padding-bottom: 6px;
        border-radius: 6px;
    }

    .color-brown {
        background: rgb(201 143 51 / 15%);
        color: #c98810;
    }

    .color-green {
        background: rgba(0, 189, 30, 0.09);
        color: #00BD1E;
    }
}