.big-calendar-custom .rbc-calendar {
  height: 100% !important;
  margin: 20px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.inventory-modal form {
  display: flex;
  flex-direction: column;
}

.inventory-modal h3 {
  margin-top: 10;
  margin-bottom: 20;
  text-align: center;
}
.calenderLoader {
  backdrop-filter: blur(3px);
}

.rbc-day-slot .rbc-events-container .rbc-event{
  width: 100% !important;
  left: 0 !important;
}

.big-calendar-custom
  .rbc-calendar
  .rbc-toolbar
  .rbc-btn-group:first-child
  button {
  background-color: transparent !important;
  color: rgb(0, 0, 0);
}
.big-calendar-custom
  .rbc-calendar
  .rbc-toolbar
  .rbc-btn-group:first-child
  button:active {
  color: rgb(0, 0, 0) !important;
}

.big-calendar-custom
  .rbc-calendar
  .rbc-toolbar
  .rbc-btn-group:first-child
  button:hover {
  background-color: rgba(170, 170, 170, 0.251) !important;
}

.big-calendar-custom
  .rbc-calendar
  .rbc-toolbar
  .rbc-btn-group:last-child
  button {
  color: rgb(0, 0, 0);
}

/* TRIGGER ALL SECTION AS DAY */

.big-calendar-custom .rbc-calendar .rbc-month-row .rbc-row-content {
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
}
.big-calendar-custom
  .rbc-calendar
  .rbc-month-row
  .rbc-row-content
  .rbc-row:first-child {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
}
.big-calendar-custom
  .rbc-calendar
  .rbc-month-row
  .rbc-row-content
  .rbc-row:nth-child(2) {
  margin-top: 20px !important;
  padding-right: 0px !important;
}

.big-calendar-custom
  .rbc-calendar
  .rbc-month-row
  .rbc-row-content
  .rbc-row:first-child
  div {
  padding-right: 0px !important;
}

.big-calendar-custom
  .rbc-calendar
  .rbc-month-row
  .rbc-row-content
  .rbc-row:first-child
  div
  button {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: end;
  padding-right: 3px;
  padding-top: 3px;
}

@media(max-width:575px){
  .inventory-title{
    padding-left: 10px !important;
    background-color: green;
  }
  .inventory-title h3{
    font-size: 20px !important;
  }
}